<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  
  mounted() {
    //校验用户是否已经登录
    this.status = localStorage.getItem("status")
    console.info('tag', status)
    if(this.status){
      //跳转至列表
      console.info('tag', "已登录")
      // this.$router.push("/list");
    }else{
      //未登录
      console.info('tag', "未登录")
      this.$router.push("/login");
    }
  },
}
</script>

<style>
*{
  margin: 0;
} 
</style>